import React from "react"

const Loader = () => {
  const css = `
    @keyframes spinLoaderSav {
      0% {
        transform: rotate(0deg) scale(1);
      }
      50% {
          transform: rotate(180deg) scale(0.8);
      }
      100% {
          transform: rotate(360deg) scale(1);
      }
    }
  `;

  return (
    <>
      <style>{css}</style>
      <div style={{margin: '4px'}}>
        <div style={{
          width: '25px',
          height: '25px',
          border: '2px solid',
          borderColor: '#0D629A',
          margin: '4px',
          borderBottomColor: 'transparent',
          borderRadius: '100%',
          background: 'transparent !important',
          verticalAlign: 'middle',
          animation: 'spinLoaderSav 0.75s 0s infinite linear',
          animationFillMode: 'both',
          display: 'inline-block',
        }} />
      </div>
    </>
  )
};

export default Loader;
